<template>
  <div class="hello">
    <h1>{{ msg }}</h1>
    <!-- <audio ref="audio" src="../assets/心要野.mp3" controls loop ></audio> -->
    <!-- <video width="480" autoplay muted ref="audio">
      <source src="../assets/abc.mp4" type="video/mp4">
      </source>
    </video>
    <video src="../assets/abc.mp4" autoplay playsinline></video> -->
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'

const audio = ref(null);

function play() {
  audio.value.muted = true;
  const ctx = new AudioContext();
  const canAudioPlay = ctx.state === 'running';
  ctx.close();
  if (canAudioPlay) {
    console.log('canAudioPlay', canAudioPlay);
    audio.value.muted = true;
    audio.value.pause();
    audio.value.play();
  } else {
    // alert('请允许浏览器播放音频')
  }
}

onMounted(() => {
  // console.log('audio', audio.value);
  // audio.value.muted = true;
  // audio.value.play();
})

defineProps({
  msg: String
})
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
