<template>
    <div class="footer">
        <span class="icp">
            <img class="beiantubiao" src="@/assets/备案图标.png">
            <a href="https://beian.mps.gov.cn/#/query/webSearch?code=34122202000368" rel="noreferrer" target="_blank">皖公网安备34122202000368</a>
            <a href="https://beian.miit.gov.cn/" target="_blank">皖ICP备2023013330号-1</a>
        </span>
        <span class="copyright">{{`© ${year} ${author} ` }}</span>
    </div>
    
</template>

<script setup>
    let year = new Date().getFullYear();
    let author = '美梦';
</script>

<style>
    .footer {
        /* margin-bottom: auto; */
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        flex-direction: column;
        font-size: 12px;
        color: #999;
        /* margin-bottom: 20px; */
    }

    .footer a {
        color: #999;
        text-decoration: none;
        margin-right: 10px;
    }

    .footer a:hover {
        color: #0052d9;
    }

    .beiantubiao {
        width: 16px;
        height: 16px;
        margin-right: 5px;
        vertical-align: middle;
    }

</style>
