<template>
    <div>
      <canvas ref="canvas" width="400" height="200"></canvas>
    </div>
  </template>
  
  <script setup>
  import { ref, onMounted, onUnmounted } from 'vue'
  
  const currentTime = ref('')
  const canvas = ref(null)
  let animationFrameId = null
  
  const drawTime = () => {
    const ctx = canvas.value.getContext('2d')
    ctx.clearRect(0, 0, canvas.value.width, canvas.value.height)
    ctx.fillStyle = 'black'
    ctx.textBaseline = 'middle'
    ctx.font = '96px DS-DIGIT, sans-serif'
    ctx.fillText(currentTime.value, (canvas.value.width - ctx.measureText(getTime()).width)/2, 100)
  }
  
  const updateClock = () => {
    currentTime.value = getTime()
    drawTime()
    animationFrameId = requestAnimationFrame(updateClock)
  }

  const getTime = () => {
    return new Date().toTimeString().substring(0, 8)
  }
  
  onMounted(() => {
    const canvasElem = canvas.value
    const ctx = canvasElem.getContext('2d')
    const fontFace = new FontFace('DS-DIGIT', 'url(./assets/DS-DIGIB.TTF)')
    fontFace.load().then(font => {
      document.fonts.add(font)
    })
    updateClock()
  })
  
  onUnmounted(() => {
    cancelAnimationFrame(animationFrameId)
  })
  </script>
  
  <style >
  canvas {
    font-family: 'DS-DIGIT', sans-serif;
    /* border: 1px solid #000; */
  }
  </style>
  